import React, { useContext, useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SendgridLogo from '../../assets/img/sendgrid.svg';
import Loader from '../../components/Loader';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import * as Api from '../../utils/api';
import { useAuth0 } from '@auth0/auth0-react';

const Subscription = () => {
  const [apiKey, setApiKey] = useState('');
  const [sendgridObj, setSendgrid] = useState();
  const notyf = useContext(NotyfContext);
  const [loading, setLoading] = useState(false);
  const customerState = useSelector((state) => state.Customer);
  const [chargebeeScriptLoaded, setChargebeeScriptLoaded] = useState(false);
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const urlReceiver =
    'http://devserver.fyncom.com.com/v2/api/auth/google/callback';
  // 'https://devserver.fyncom.com.com/v2/api/auth/google/callback';

  // This is old code from a cool payments fiat/crypto/nano widget I had called "CoinEmbed" - May consider bringing it back later
  // const [loadedCoinScript, setLoadedCoinScript] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    // const coinScript = document.createElement('script');
    script.onload = () => {
      window.Chargebee.init({ site: 'fyncom' });
      window.Chargebee.registerAgain();
      setChargebeeScriptLoaded(true); // Set chargebeeScriptLoaded to true when the script is loaded
    };
    // coinScript.onload = () => {
    //   window.CoinEmbed.loadDocumentInstances();
    //   setLoadedCoinScript(true);
    // };
    script.onerror = () => {
      setChargebeeScriptLoaded(false); // Set chargebeeScriptLoaded to false if the script fails to load
    };
    script.src = 'https://js.chargebee.com/v2/chargebee.js';
    script.async = true;
    // coinScript.src = 'https://coinembed.com/v1/coinembed.js';
    // coinScript.async = true;

    document.body.appendChild(script);
    // document.body.appendChild(coinScript);

    return () => {
      document.body.removeChild(script);
      // document.body.removeChild(coinScript);
    };
  });

  useEffect(() => {
    // getEmailApiKey();
  }, []);

  //NOTE: You may want to check if this is a "Free" subscriber before showing the Chargebee widget
  // Leaving this code here for now, just a placeholder for that "Free" check.
  const getEmailApiKey = async () => {
    try {
      let response = await Api.getDataWithoutAuth(
        ApiRoutes.getApiCredentials('SENDGRID')
      );
      setSendgrid(response);
      if (response.apiKey) {
        setApiKey(response.apiKey);
      }
    } catch (err) {
      console.log('No API Key Found!');
    }
  };

  /**
   * This only gets used when a person clicks a button.
   * It is a secondary button mostly used for testing
   */
  const getGmailAccessToken = async () => {
    console.log('const re ' + urlReceiver);
    try {
      // may not need this?
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        access_type: 'offline', // may not be needed?
        connection_scope: scope,
        scope: 'openid profile email ' + scope,
        ignoreCache: true,
        redirectUri: urlReceiver
      });
    } catch (e) {
      if (e.error === 'login_required') {
        console.log('Redirecting to login page...');
        // loginWithRedirect(); // possible that the new auth0 update fixed this.
        loginWithRedirect({
          access_type: 'offline', // may not be needed?
          connection_scope: scope,
          scope: scope, // Add this line
          connection: 'google-oauth2',
          redirectUri: urlReceiver
        });
      } else {
        console.error('Error getting access token:', e);
      }
    }
  };

  /**
   * This is the primary opt in button to start the FynCom Filters: Email Edition
   */
  const handleOptIn = async () => {
    loginWithRedirect({
      access_type: 'offline',
      connection_scope: scope,
      scope: 'openid profile email',
      connection: 'google-oauth2'
      // redirectUri: "https://server.robocashapp.com/v2/api/auth/google/callback",
    });
  };

  return (
    <div>
      {loading && <Loader />}
      <div>
        <Card>
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              Subscription Management
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Form>
              {chargebeeScriptLoaded ? (
                <Button w="100%" data-cb-type="portal">
                  Check My Subscription
                </Button>
              ) : (
                <p>
                  We could not load the subscription management button. Please
                  disable any VPN or browser extensions that might be blocking
                  the script and try again. VPNs are the most likely cause of
                  this.
                </p>
              )}
            </Form>
          </Card.Body>
        </Card>
        <div>
          <button
            onClick={() => {
              handleOptIn();
            }}
          >
            Opt-in to Email Spam Tool
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              getGmailAccessToken();
            }}
          >
            Refresh token
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
